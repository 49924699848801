<template>
  <div>
    <van-nav-bar
      title="出库刷卡"
      left-text="返回"
      left-arrow
      @click-left="goBack"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="code"
        required
        name="卡号"
        label="卡号"
        placeholder="卡号"
        readonly
        is-link
        @click="show = true"
      />
      <van-field
        required
        v-model="weightNum"
        type="number"
        name="重量"
        label="重量"
        placeholder="重量"
        :rules="[{ required: true, message: '请填写重量' }]"
      >
        <div slot="right-icon">kg</div>
      </van-field>
      <div class="flexd-btn">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>

    <van-popup v-model="show" position="bottom">
      <van-cell-group>
        <van-cell
          v-for="item in codeList"
          @click="checkDroup(item)"
          :title="item.name"
          is-link
          :value="item.code"
          :key="item.code"
        />
      </van-cell-group>
    </van-popup>
  </div>
</template>
<script>
import { Notify } from "vant";
import { slotCard, upload } from "../utils/api";
import { Toast } from 'vant';
export default {
  data() {
    return {
      code: null,
      groupId: 3, //集团id
      companyId: 5, //分站id
      weightNum: 900,
      equipmentId: 1004, //分站id
      codeList: [
        { code: "9191910007" },
        { code: "9191910008" },
        { code: "9191910009" },
      ],
      show: false,
    };
  },
  methods: {
    onClickLeft() {},
    onSubmit() {
      let { code, groupId, weightNum, companyId, equipmentId } = this;
      if (!code) {
        Notify("请选择卡号！");
        return
      }
      if(this.isload ){
        return
      }
      this.isload = true
      slotCard({
        code,
        groupId,
        companyId,
        weightNum,
        equipmentId,
      }).then((res) => {
        
        let { respCode, msg, data } = res;
        if (respCode == "100200") {
          Toast( "过磅成功" );
          let { collectId, picType } = data;
          this.goUpload({
            collectId,
            picType,
            companyId,
          });
          setTimeout(() => {
            this.goBack();
          }, 2000);
        } else {
          Notify(msg);
          this.isload = false
        }
      });
    },
    //上传图片
    goUpload(data) {
      let { collectId, picType, companyId } = data;
      let picList = [];
      if (picType == 1) {
        picList = [
          "/出库-皮重1.jpg",
          "/出库-皮重2.jpg",
        ];
      } else {
        picList = [
          "/出库-毛重1.jpg",
          "/出库-毛重2.jpg",
        ];
      }
      picList.forEach((item) => {
        upload({
          collectId,
          picType,
          companyId,
          fileName: item,
          uploadType: "2",
        });
      });
    },
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    checkDroup(item) {
      this.code = item.code;
      this.show = false;
    },
  },
};
</script>
<style scoped>
.flexd-btn {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px;
  box-sizing: border-box;
}
</style>
